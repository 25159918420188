exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-countries-js": () => import("./../../../src/pages/countries.js" /* webpackChunkName: "component---src-pages-countries-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-tag-js": () => import("./../../../src/pages/tag.js" /* webpackChunkName: "component---src-pages-tag-js" */),
  "component---src-pages-vineyards-js": () => import("./../../../src/pages/vineyards.js" /* webpackChunkName: "component---src-pages-vineyards-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-winery-js": () => import("./../../../src/templates/winery.js" /* webpackChunkName: "component---src-templates-winery-js" */)
}

